import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="app-footer">
                <p>&copy; Copyright 2020 - All Rights Reserved</p>
            </footer>
        );
    }
}

export default Footer;
